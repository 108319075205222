<template>
  <div>
    <b-modal
      id="modal-stranger"
      centered
      size="lg"
      :hide-footer="true"
      ref="modal-stranger"
      :title="stranger.in_time"
    >
      <b-img :src="stranger.image" style="width:100%"/>
    </b-modal>
    <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>Statistics</b-card-title>
        </b-card-header>
        <b-card-body class="">
          <b-row>
            <b-col
              xl="6"
              sm="6"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-primary"
                  >
                    <feather-icon
                       size="24"
                      icon="ClipboardIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body style="cursor: pointer;" class="my-auto">

                  <h4 class="font-weight-bolder mb-0">
                    {{ stat.members }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    出入人數
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col
              xl="6"
              sm="6"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="RefreshCwIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body style="cursor: pointer;" class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ stat.records }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    出入次數
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

           



          </b-row>
        </b-card-body>
      </b-card>
    <b-row>
      <b-col cols="2">
      <b-card>
        <b-form-group
          label="Type"
          label-for="Type"
        >
        <v-select
         v-model="selected_status"
         :reduce="status => status.value"
        :options="statusOptions"
        :clearable="false"
        :filterable="false" />
      
    </b-form-group>
    <b-form-group
          label="Date"
          label-for="Date"
        >
      <flat-pickr
          v-model="rangeDate"
          class="form-control mb-1 mr-1"
          :config="{ mode: 'single'}"
        />
        </b-form-group>
        <b-form-group
          label="From "
          label-for="Start "
        >
        <b-form-input
                 id="from"
                  v-mask="'##:##'"
                 v-model="time.from"
                 trim
               />
      </b-form-group>
      <b-form-group
          label="To "
          label-for="To"
        >
        <b-form-input
                 id="to"
                  v-mask="'##:##'"
                 v-model="time.to"
                 trim
               />
      </b-form-group>
      <b-button
            variant="primary"
            class="my-2"
            @click="get_data()"
            block
          >
            <span class="text-nowrap">Submit</span>
          </b-button>
        <hr class="my-2"/>
          <b-button
            variant="outline-primary"
            class="mb-1"
            block
            @click="exportToExcel()"
          >
            <span class="text-nowrap">Export</span>
          </b-button>
      </b-card>
      </b-col>
      <b-col cols="10">
   
    <!-- table -->
        <b-card>
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      @row-selected="stranger_image"
      :fields="fields"
      responsive
      striped
      class="mb-0"
    >
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(target_name)="data">
         <b-link v-if="data.item.target_type == 'MEMBER'" :to="'/apps/member/edit/'+data.item.taget_token+'/'"><b>{{data.item.target_name}}</b></b-link>
         <b-link v-if="data.item.target_type == 'STAFF'" :to="'/apps/staff/edit/'+data.item.taget_token+'/'"><b>{{data.item.target_name}}</b></b-link>
         <span v-if="data.item.tags !== null">
         <b-badge v-for="tag in data.item.tags" :key="index" class='ml-1' :variant="'warning'">
          {{tag}}
        </b-badge>
        </span>
        </template>
      <template #cell(status_number)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>

    <b-row>
      <b-col class="mt-1">
        Total Rows : {{ totalRows }}
      </b-col>
      <b-col>

      </b-col>
    </b-row>
  </b-card>
    </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import {
  BMedia,
  BMediaAside,
  BCardHeader,
  BCardTitle,
  BMediaBody,BCard, BImg , BTable,BTime, BAvatar, BCardBody, BLink,BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,BModal, VBModal} from 'bootstrap-vue'
export default {
  components: {
    moment,
    BImg,
    BCard,
    BCardHeader,
    BCardTitle,
    flatPickr,
    BTable,
    BModal, VBModal,
    BAvatar,
    BLink,
    BBadge,
    BRow,
    BCol,
    BMedia,
  BMediaAside,
  BMediaBody,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BTime,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected_status : 'ALL',
      rangeDate: null,
      test_string : null,
      user_id : 'all',
      stat : {
        records : 0,
        members : 0
      },
      staffOptions : [],
      headerTitle: ['#', 'STAFF','CLIENT', 'START AT', 'END AT', 'SALARY','ATTEND','REMARKS'],
      headerVal: ['id', 'display_name','member_name', 'start', 'end', 'salary','attend','remarks'],
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'GRANT', value: 'GRANT' },
        { label: 'DENY', value: 'DENY' },
        { label: 'WARNING', value: 'WARNING' },
      ],
      centerOptions: [],
      salary: 0,
      isAddNewMemberSidebarActive: false,
      stranger : {
        in_time : null
      },
      searchString: null,
      time:{
        from:'00:00',
        to:'23:59'
      },
      stranger_image_src: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [ { key: 'center_name', label: 'Center' },{ key: 'access_device', label: 'Device' }, { key: 'target_type', label: 'Type' }, { key: 'target_name', label: 'Name' },{ key: 'in_time', label: 'IN' }, { key: 'out_time', label: 'OUT' }, { key: 'status_number', label: 'STATUS' }],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Grant', 2: 'Deny',3:'Warning'
      },
      {
        1: 'light-success', 2: 'light-danger',3: 'light-warning'
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    user_id : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    rangeDate : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    selected_status : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
  },
  methods: {
    exportToExcel() {
    //  console.log(this.rangeDate)
      import('@/@core/Export2Excel').then(excel => {
        const list = this.items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'pt-report',
          autoWidth: true,
          stringifyLongNum:true,
          bookType: 'xlsx'
        })
        this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          // Add col name which needs to be translated
           if (j === 'salary') {
             return parseFloat(v[j])
           } else {
             return j+v[j]
           }

          return v[j]
        })
      )
    },
    get_data() {
      var splitted = []
      if (this.rangeDate === null) {
        this.rangeDate = moment().format('YYYY-MM-DD')
      } else {
      //  splitted = this.rangeDate.split(" to ");
      }
      this.$http.get(process.env.VUE_APP_API_BASE+`/report/entry/`+this.$store.getters['center/currentCenterId'] +`/?status=`+this.selected_status+`&date=`+this.rangeDate+`&from=`+this.time.from+`&to=`+this.time.to)
        .then(res => {
          this.items = res.data.records
          this.stat = res.data.stat
          this.totalRows = this.items.length
    //        console.log( res.data )
        })
    },
    stranger_image(image) {
      this.stranger = image[0]
      console.log(this.stranger)
      if (image[0].target_type != 'STRANGER') { return }
      this.stranger.image = 'data:image/jpg;base64,'+image[0].image

      this.$refs['modal-stranger'].show()
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      console.log(items)
      if (items[0].is_pdf == 'Y') {
        //this.iframe_src = '//www.cloudfit.pro/pdf/INVOICE_'+items[0].code+'.pdf'
        //console.log(this.iframe_src)

      } else {
      //this.iframe_src = process.env.VUE_APP_API_BASE+'/invoice.php?print=n&code='+items[0].id
      }
      //this.transaction_id = items[0].transaction_id
    //  this.$refs['my-modal'].show()
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
